import { useGlobalContext } from "../Context/Context";
import "./profile.css";
const SelectBox = ({
  selectedItems,
  setSelectedItems,
  setUserData,
  userData,
  selectedItemsStart,
}) => {
  console.log(selectedItems);
  const { items, value } = selectedItems;
  const { letterSize, boldItalic, fontSize, fontStyle } = useGlobalContext();

  const handleSelectItem = (item) => {
    const key = Object.keys(userData.data)[value];
    let newData;
    if (value === 4) {
      // jezici
      const selectedLanguage = userData.data[key].find((lang) => lang === item);
      let newLanguages = [];
      if (selectedLanguage) {
        // brisanje
        newLanguages = userData.data[key].filter((lang) => lang === item);
      } else {
        // dodavanje
        newLanguages = [...userData.data[key], item];
      }
      newData = { ...userData.data, [key]: newLanguages };
    } else newData = { ...userData.data, [key]: item };
    setUserData({ ...userData, data: newData });
    setSelectedItems(selectedItemsStart);
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const checkIfSelected = (item) => {
    const itemValue = Object.values(userData.data)[value];
    if (value === 4) {
      // jezici
      return itemValue.find((lang) => lang === item);
    } else return itemValue === item;
  };

  return (
    <div className="select-box">
      {items.map((item, index) => {
        const { id, name } = item;
        return (
          <span
            className={
              `${
                checkIfSelected(name, index)
                  ? "select-box-item selected"
                  : "select-box-item"
              }` +
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            key={id}
            onClick={() => handleSelectItem(name)}
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {handleConvertText(name)}
          </span>
        );
      })}
    </div>
  );
};

export default SelectBox;
