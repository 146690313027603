import React, { useState, useEffect } from "react";
import "./logoPage.css";
import android from "../picture/Android.png";
import apple from "../picture/Apple.png";
import logo from "../picture/Tempo.png";
import youtube from "../picture/Youtube.png";
import www from "../picture/Www.png";
import { useGlobalContext } from "../Context/Context";

const LogoPage = ({ setCurrentPage }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallVisible, setIsInstallVisible] = useState(false);
  const { isAndroid, setIsAndroid, isIOS, setIsIOS } = useGlobalContext();

  useEffect(() => {
    const interval = setTimeout(() => {
      setCurrentPage(1);
    }, 4000);

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallVisible(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Detect if the app is installed
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

    if (!isStandalone) {
      // Detect the user's device
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (/android/.test(userAgent)) {
        setIsAndroid(true);
        setIsIOS(false); // Ensure only one is true
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        setIsIOS(true);
        setIsAndroid(false); // Ensure only one is true
      }
    } else {
      // If the app is already installed, hide the install buttons
      setIsInstallVisible(false);
    }

    return () => {
      clearTimeout(interval);
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, [setCurrentPage]);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;
    deferredPrompt.prompt();
    const choiceResult = await deferredPrompt.userChoice;
    if (choiceResult.outcome === "accepted") {
      console.log("User accepted the install prompt");
    } else {
      console.log("User dismissed the install prompt");
    }
    setDeferredPrompt(null);
    setIsInstallVisible(false);
  };

  return (
    <>
      <div className="wrapper-logo">
        {isInstallVisible && (
          <div className="android-apple">
            <img
              src={apple}
              alt="Install on iOS"
              onClick={isIOS ? handleInstallClick : null}
              className={isIOS ? "glow" : ""}
            />
            <img
              src={android}
              alt="Install on Android"
              onClick={isAndroid ? handleInstallClick : null}
              className={isAndroid ? "glow" : ""}
            />
          </div>
        )}
        <div
          className="logo"
          onClick={() => setCurrentPage(1)}
          onTouchStart={() => setCurrentPage(1)}
        >
          <img src={logo} alt="Logo" />
        </div>
        <div className="youtube-link">
          <img src={youtube} alt="Youtube" />
          <img src={www} alt="Website" />
          <img src={youtube} alt="Youtube" />
        </div>
      </div>
      <div className="wrapper-logo-mobile">
        <div
          className="logo"
          onClick={() => setCurrentPage(1)}
          onTouchStart={() => setCurrentPage(1)}
        >
          <img src={logo} alt="Logo" />
        </div>
        {isInstallVisible && (
          <div className="wrapper-logo-mobile-left">
            <div className="android-apple">
              <img
                src={apple}
                alt="Install on iOS"
                onClick={isIOS ? handleInstallClick : null}
                className={isIOS ? "glow" : ""}
              />
              <img
                src={android}
                alt="Install on Android"
                onClick={isAndroid ? handleInstallClick : null}
                className={isAndroid ? "glow" : ""}
              />
            </div>
            <div className="youtube-link">
              <img src={youtube} alt="Youtube" />
              <img src={www} alt="Website" />
              <img src={youtube} alt="Youtube" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LogoPage;
