import { useEffect, useState } from "react";
import "./profile.css";
import ProfileLogin from "./ProfileLogin";
import ProfileSelect from "./ProfileSelect";
import ProfileCheckout from "./ProfileCheckout";
import axios from "axios";
import { useGlobalContext } from "../Context/Context";

const Profile = () => {
  const { loggedUser } = useGlobalContext();
  let userStarterData = {
    email: "",
    name: "",
    picture: "",
    data: {
      qualification: "",
      profession: "",
      workField: "",
      workExperience: "",
      languages: [],
    },
    address: "",
    phone: "",
    cv: "",
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [userData, setUserData] = useState(userStarterData);

  useEffect(() => {
    const getItems = async () => {
      const baseUrl = "https://data.nadzor.app/api/";
      const url = baseUrl + "profiles/getProfileByUserId.php";
      const requestObject = {
        user_id: loggedUser.id,
        api_key:
          "c4222563ed7114b9ed64f5bd3120f5cdb1a8aac9f98e7cf7bda1049d54e4c168",
      };
      const { data } = await axios.post(url, requestObject);
      console.log(data);

      if (data.profile.user_name !== "") {
        const currentData = {
          email: data.profile.user_email,
          name: data.profile.user_name,
          picture: data.profile.cp_image,
          data: {
            qualification: data.profile.up_stepen_kvalifikacije,
            profession: data.profile.up_profesija,
            workField: data.profile.up_oblast_rada,
            workExperience: data.profile.up_radno_iskustvo,
            languages: data.profile.languages,
          },
          address: data.profile.up_adresa,
          phone: data.profile.up_broj,
          cv: data.profile.up_cv,
        };
        setUserData(currentData);
        setCurrentPage(2);
      }
    };
    getItems();
  }, []);

  return (
    <div className="profile-container">
      {currentPage === 0 && (
        <ProfileLogin
          setCurrentPage={setCurrentPage}
          userData={userData}
          setUserData={setUserData}
        />
      )}
      {currentPage === 1 && (
        <ProfileSelect
          setCurrentPage={setCurrentPage}
          userData={userData}
          setUserData={setUserData}
        />
      )}
      {currentPage === 2 && (
        <ProfileCheckout
          setCurrentPage={setCurrentPage}
          userData={userData}
          setUserData={setUserData}
        />
      )}
    </div>
  );
};

export default Profile;
