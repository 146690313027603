import React from "react";
import "./languagesHeader.css";
import Button from "../../Button/Button";

// Icons
import Back from "./icons/back.png";
import Share from "../../picture/Share.png";
import Close from "./icons/close.png";
import { useGlobalContext } from "../../Context/Context";

function LanguagesHeader({
  shareBack,
  setCurrentPage,
  currentPage,
  setCurrentPageGlobal,
}) {
  console.log(shareBack);
  const { typeButton, letterSize } = useGlobalContext();

  const handleClickBack = (shareBack) => {
    if (shareBack && currentPage > 0) {
      if (currentPage === 3) setCurrentPage(currentPage - 2);
      else if (currentPage === 5) setCurrentPage(0);
      else setCurrentPage(currentPage - 1);
    }
  };

  const handleClickClose = () => {
    console.log("Vracam na prvi ekran");
    setCurrentPageGlobal(0);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'TEMPOLEX',
        text: 'Registruj se besplatno!',
        url: "https://testpwa.nadzor.app",
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  return (
    <div className="languages-header-container">
      <div className="languages-header">
      <div
  className="languages-header-button"
  onClick={shareBack ? () => handleClickBack(true) : handleShare}
>
  <Button
    className="back-button"
    typeButton={typeButton}
    colorActive={true}
  >
    <div className="language-header-bar">
      <img
        src={shareBack ? Back : Share}
        className="visible-img"
        alt=""
      />
    </div>
  </Button>
</div>
        
<div
  className="languages-header-button"
  onClick={handleClickClose}
>
  <Button
    className="close-button"
    typeButton={typeButton}
    colorActive={true}
  >
    <div className="language-header-bar">
      <img src={Close} alt="" className="visible-img" />
    </div>
  </Button>
</div>
</div>
</div>
  );
}

export default LanguagesHeader;
