import React, { useState } from "react";

import CircledButton from "../../buttons/circled-button/CircledButton";
import FeedPost from "../../posts/feed-post/FeedPost";

import { ApiData } from "../../data/api";
import { useGlobalContext } from "../../../Context/Context";

import "./userFeed.css";
import { 
    BackIdleIcon, 
    BlockIdleIcon, 
    FavIdleIcon, 
    FollowersIdleIcon, 
    FollowingIdleIcon, 
    GearActiveIcon, 
    GearIdleIcon, 
    OtherActiveIcon, 
    OtherIdleIcon, 
    UserfeedActiveIcon, 
    UserfeedIdleIcon 
} from "../../assets/icons";
import { Link } from "react-router-dom";

function UserFeed() {
  const [showSecondButtons, setShowSecondButtons] = useState(false); // State to toggle second row
  const [showUserFeed, setShowUserFeed] = useState(true); // State to toggle user feed

  const firstPost = ApiData.posts[0];

  const handleThirdButtonClick = () => {
    setShowSecondButtons(!showSecondButtons); // Toggle second buttons visibility
    setShowUserFeed(!showUserFeed); // Toggle user feed visibility
  };

  const { apiKey } = useGlobalContext();

  console.log(apiKey);

  return (
    <div className="user-feed-wrapper">
      <div className="top-buttons">
        <Link to="/social/feed">
          <CircledButton idleImage={BackIdleIcon} handleButtonEffect={() => console.log("Test")}/>
        </Link>
        <CircledButton idleImage={UserfeedIdleIcon} activeImage={UserfeedActiveIcon} toggle={true} handleButtonEffect={()=>console.log("dsads")}/>
        <CircledButton 
          idleImage={OtherIdleIcon} 
          activeImage={OtherActiveIcon} 
          toggle={true} 
          handleButtonEffect={handleThirdButtonClick} // Handle third button click
        />
        <CircledButton idleImage={GearIdleIcon} activeImage={GearActiveIcon} handleButtonEffect={()=>console.log("dsads")} />
      </div>

      {showSecondButtons && (
        <div className="second-buttons">
          <CircledButton idleImage={FollowingIdleIcon}/>
          <CircledButton idleImage={FollowersIdleIcon}  toggle={true} handleButtonEffect={()=>console.log("dsads")}/>
          <CircledButton idleImage={BlockIdleIcon} toggle={true} handleButtonEffect={()=>console.log("dsads")}/>
          <CircledButton idleImage={FavIdleIcon} handleButtonEffect={()=>console.log("dsads")} />
        </div>
      )}

      {showUserFeed && (
        <div className="user-feed">
          <FeedPost {...firstPost} />
          <FeedPost {...firstPost} />
          <FeedPost {...firstPost} />
          <FeedPost {...firstPost} />
          <FeedPost {...firstPost} />
          <FeedPost {...firstPost} />
          <FeedPost {...firstPost} />
          <FeedPost {...firstPost} />
        </div>
      )}
    </div>
  );
}

export default UserFeed;
