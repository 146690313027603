import axios from 'axios'

const baseUrl = 'https://data.nadzor.app/api'


export const fetchData = async () => {
  try {
      let {data} = await axios.get(
        `${baseUrl}/languages/ReadMainLanguages.php`,
        {}
      );
      console.log()
      return data.data
  } catch (error) {
      return(error)
  }
}


