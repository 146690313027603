import { useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"
import { ArrowRight, BackIdleIcon, CommentIcon, CommentIdleIcon, EditIcon, EyeClosed, EyeIcon, EyeIdleIcon, GearIdleIcon, HalfOpenEye, NearClosedEye, OpenBookIcon, OpenEyeIcon, PaperplaneIcon, PlaneIdleIcon, PostsIcon, SearchIdleIcon, SearchUserIcon, ShareIcon, StarIcon, StarIdleIcon, ThickBorderedStar, UserIdle } from "../../assets/icons"

import './landingPage.css'

const ListItem = ({icon, value, key, extraStyling}) => {
    return (<li key={key} className="landing-page--book--stats--left-list--item landing-page--book--stats--list--item flex-apart">
        <img src={icon} alt="stat" style={extraStyling} /> 
        <p>{value}</p>
    </li>)
}

const statList = [{'icon': OpenEyeIcon, 'value': 5237, 'extraStyling': {height: '1.4rem'}},{'icon': CommentIdleIcon, 'value': 5, 'extraStyling': {marginLeft: '4px'}}, {'icon': PlaneIdleIcon, 'value': 2}, {'icon': ThickBorderedStar, 'value': 6, 'extraStyling': {marginLeft: '5px'}}, {'icon': PostsIcon, 'value': 3237}, {'icon': UserIdle, 'value': 52}, {'icon': SearchUserIcon, 'value': 22}, {'icon': ShareIcon, 'value': 2}]
const colorGuides = [{'color': 'red', "message": 'Serious problem'},{'color': 'yellow', "message": 'Not so Serious problem'}, {'color': 'green', "message": 'Casual situation without lorem ipsum'}, {'color': 'blue', "message": 'Lorem ipsum repeat after'}]

const eyeIconsList = [EyeClosed, NearClosedEye, HalfOpenEye, HalfOpenEye, OpenEyeIcon, OpenEyeIcon ]

const leftStatList = statList?.slice(0,4)
const rightStatList = statList?.slice(4,8)

const LandingPage = () => {

    const [visibilityCounter, setVisibilityCounter] = useState(5)
    const [isTimerReset, setIsTimerReset] = useState(false)

    const navigate = useNavigate()
    let counter = 5;

    useEffect(() => {
        if (isTimerReset) {setVisibilityCounter(counter);
            setTimeout(() => {
                setIsTimerReset(false)
            },1000)
        }

        else setTimeout(() => {
            setVisibilityCounter(visibilityCounter - 1)
        },1000)

        if (visibilityCounter === 0 && !isTimerReset) navigate('feed')

    }, [visibilityCounter, isTimerReset])
    


  return (
    <div className="landing-page flex-center-col" style={{opacity: visibilityCounter / 4, position: 'relative'}}>
        {/* <div className="landing-page--close-icon" style={{position: 'absolute', top: 0, right: 0}} onClick={() => navigate('feed')}>
            <img src={BackIdleIcon} alt="close" style={{ height: '4rem', width: '3rem', cursor: 'pointer'}} />
        </div> */}
        <div className="landing-page--book">
            <img src={OpenBookIcon} alt="Open book" className="landing-page--book--main-img" />
            <div className="landing-page--book--stats">
                <ul className="landing-page--book--stats--left-list landing-page--book--stats--list">
                    {leftStatList.map((stat, key)=> (
                        <ListItem key={key} icon={stat.icon} value={stat.value} extraStyling={stat?.extraStyling} />
                    ))}
                </ul>
                <ul className="landing-page--book--stats--right-list landing-page--book--stats--list">
                    {rightStatList.map((stat, key)=> (
                        <ListItem key={key} icon={stat.icon} value={stat.value} />
                    ))}
                </ul>
            </div>
        </div>
        <div className="landing-page--description">
            <p className="landing-page--description--text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod tincidunt lacus ac sollicitudin.
            </p>
            <ul className="landing-page--description--color-list">
                {colorGuides.map((col, key) => (
                    <li className="color-guide" key={key}>
                        <div className="colored-box" style={{height: '15px', width: '15px', backgroundColor: col.color}} />
                        <p className="color-description">
                            {col.message}
                        </p>
                    </li>
                ))}
            </ul>
            <p className="landing-page--description--text">
            Cras hendrerit rutrum odio. Nam eu diam dui. Nullam vitae ultricies erat, in venenatis sem. Ut sed ultrices ligula.
            </p>
        </div>
        <div className="landing-page--buttons flex-apart" style={{width: '90%', alignItems: 'center'}}>
            <div className="landing-page--buttons--share-btn landing-page--button">
                <img src={ShareIcon} alt="" />
            </div>
            <div className="landing-page--buttons--counter" style={{color: (visibilityCounter < 3) && 'red'}}>
                {visibilityCounter}
            </div>
            <div className="landing-page--buttons--eye-btn landing-page--button" onClick={() => setIsTimerReset(true)}>
                <img src={eyeIconsList[visibilityCounter]} alt="Eye" style={{height: '3.5rem', width: '5rem', opacity: 1, zIndex: 5}} />
            </div>
        </div>
        <div className="landing-page--skip-button" onClick={() => navigate('feed')}>
            <img src={ArrowRight} alt="Skip" style={{width: '30px'}} />
        </div>
    </div>
  )
}

export default LandingPage