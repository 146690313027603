import React, { useState, useRef, useEffect } from "react";
import "./Style.css";
import DefinePin from "../DefinePin/DefinePin";
import user from "../picture/User.png";
import house from "../picture/House.png";
import share from "../picture/Share.png";
import calendar from "../picture/Calendar.png";
import bell from "../picture/Bell.png";
import home from "../picture/Home.png";
import notes from "../picture/Notes.png";
import poruke from "../picture/Message.png";
import settings from "../picture/Settings.png";
import forum from "../picture/forum.png";
import { useGlobalContext } from "../Context/Context";
import Button from "../Button/Button";

function Sidebar({ setCurrentPage }) {
  const landscape = 700;
  const { loggedUser } = useGlobalContext();
  const { typeButton } = useGlobalContext();
  const [isVisible, setIsVisible] = useState(false); // Default to hidden
  console.log(loggedUser);
  const [selectAccount, setSelectAccount] = useState(
    loggedUser.defaultHomePage
  );
  const [selectAccountPin, setSelectAccountPin] = useState(
    loggedUser.isPatternSet ? 1 : 0
  );

  const startY = useRef(0);
  const startX = useRef(0);
  const isLandscape = useRef(window.innerWidth > landscape);

  const handleStyleDefinePin = (visible) => {
    const definePin = document.querySelector(".definePin-container");
    const userManual = document.querySelector(".user-manual-wrapper");
    if (definePin !== null) {
      definePin.style.width = "100vw";
    }
    if (userManual !== null) {
      if (!visible) {
        userManual.style.width = `100vw`;
      } else {
        userManual.style.width = "100vw";
      }
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: "TEMPOLEX",
        text: "Regitruj se besplatno!",
        url: "https://testpwa.nadzor.app",
      })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  const handleBackHome = () => {
    setSelectAccount(0);
    if (selectAccountPin !== 0) setSelectAccountPin(1);
    if (isLandscape.current) {
      setIsVisible(true); // Slide up
    } else {
      setIsVisible(false); // Slide right
      handleStyleDefinePin(false);
    }
  };

  const handleSettings = () => {
    if (selectAccount === 4) {
      setSelectAccount(0);
      if (selectAccountPin !== 0) setSelectAccountPin(1);
    } else {
      setSelectAccount(4);
    }
    if (isLandscape.current) {
      setIsVisible(true); // Slide up
    } else {
      setIsVisible(false); // Slide right
      handleStyleDefinePin(false);
    }
  };

  const handleLinkLocation = () => {
    window.location.href = "/social/feed";
    if (isLandscape.current) {
      setIsVisible(true); // Slide up
    } else {
      setIsVisible(false); // Slide right
      handleStyleDefinePin(false);
    }
  };

  const handleMessage = () => {
    if (selectAccount === 5 || selectAccount === 13) {
      setSelectAccount(0);
      if (selectAccountPin !== 0) setSelectAccountPin(1);
    } else {
      setSelectAccount(5);
    }
    if (isLandscape.current) {
      setIsVisible(true); // Slide up
    } else {
      setIsVisible(false); // Slide right
      handleStyleDefinePin(false);
    }
  };

  const handleProfile = () => {
    if (selectAccount === 12) {
      setSelectAccount(0);
      if (selectAccountPin !== 0) setSelectAccountPin(1);
    } else {
      setSelectAccount(12);
    }

    if (isLandscape.current) {
      setIsVisible(true); // Slide up
    } else {
      setIsVisible(false); // Slide right
      handleStyleDefinePin(false);
    }
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.innerWidth < landscape) {
        isLandscape.current = false;
      } else {
        isLandscape.current = true;
      }
    };

    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const handleMouseMove = (e) => {
    const deltaY = getClientY(e) - startY.current;
    const deltaX = getClientX(e) - startX.current;
    if (isLandscape.current) {
      if (deltaY > -10) {
        setIsVisible(true); // Slide up
      } else if (deltaY < 10) {
        setIsVisible(false); // Slide down
      }
    } else {
      if (deltaX < -10) {
        setIsVisible(true); // Slide left
        handleStyleDefinePin(true);
      } else if (deltaX > 10) {
        setIsVisible(false); // Slide right
        handleStyleDefinePin(false);
      }
    }
  };

  const handleTouchMove = (e) => {
    const deltaY = getClientY(e) - startY.current;
    const deltaX = getClientX(e) - startX.current;
    if (isLandscape.current) {
      if (deltaY > -10) {
        setIsVisible(true); // Slide down
      } else if (deltaY < 10) {
        setIsVisible(false); // Slide up
      }
    } else {
      if (deltaX < -10) {
        setIsVisible(true); // Slide left
        handleStyleDefinePin(true);
      } else if (deltaX > 10) {
        setIsVisible(false); // Slide right
        handleStyleDefinePin(false);
      }
    }
  };

  useEffect(() => {
    const handleMouseDown = (e) => {
      startY.current = getClientY(e);
      startX.current = getClientX(e);

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    const handleTouchStart = (e) => {
      startY.current = getClientY(e);
      startX.current = getClientX(e);
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    };

    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };

    const sidebar = document.querySelector(".sidebar");
    if (sidebar) {
      sidebar.addEventListener("mousedown", handleMouseDown);
      sidebar.addEventListener("touchstart", handleTouchStart);
    }

    return () => {
      if (sidebar) {
        sidebar.removeEventListener("mousedown", handleMouseDown);
        sidebar.removeEventListener("touchstart", handleTouchStart);
      }
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isVisible]);

  const getClientY = (e) => {
    if (e.touches !== undefined) {
      return e.touches[0].clientY;
    } else return e.clientY;
  };

  const getClientX = (e) => {
    if (e.touches !== undefined) {
      return e.touches[0].clientX;
    } else return e.clientX;
  };

  const handleCloseSideBar = () => {
    if (isLandscape.current) {
      setIsVisible(true); // Slide up
    } else {
      setIsVisible(false); // Slide right
      handleStyleDefinePin(false);
    }
  };

  console.log("Ovo je select acount pin", selectAccount);

  return (
    <>
      <DefinePin
        selectAccount={selectAccount}
        setSelectAccount={setSelectAccount}
        setCurrentPage={setCurrentPage}
        setSelectAccountPin={setSelectAccountPin}
        selectAccountPin={selectAccountPin}
      />
      <div
        className={`mask ${isVisible ? "visible" : "hidden"}`}
        onClick={handleCloseSideBar}
      />
      <div
        className={
          window.innerWidth > 700
            ? `sidebar sidebar-landscape ${isVisible ? "visible" : "hidden"}`
            : `sidebar ${isVisible ? "visible" : "hidden"}`
        }
        style={
          selectAccountPin === 0
            ? { pointerEvents: "none" }
            : { pointerEvents: "auto" }
        }
      >
        <div className="back-home-button" onClick={handleProfile}>
          <Button active={selectAccount === 12} typeButton={typeButton}>
            <img src={user} alt="user" />
          </Button>
        </div>
        <div className="back-home-button" onClick={() => handleLinkLocation()}>
          <Button active={selectAccount === 6} typeButton={typeButton}>
            <img src={forum} alt="forum" />
          </Button>
        </div>

        {/* <div className="back-home-button" onClick={() => setSelectAccount(6)}>
          <Button active={selectAccount === 6} typeButton={typeButton}>
            <img src={forum} alt="forum" />
          </Button>
        </div> */}
        <div className="back-home-button" onClick={() => handleBackHome()}>
          <Button active={selectAccount === 0} typeButton={typeButton}>
            <img src={home} alt="home" />
          </Button>
        </div>
        <Button typeButton={typeButton} activation={true}>
          <img src={notes} alt="notes" />
        </Button>
        <Button typeButton={typeButton} activation={true}>
          <img src={calendar} alt="calendar" />
        </Button>
        <Button typeButton={typeButton} activation={true}>
          <img src={bell} alt="bell" />
        </Button>
        <div className="back-home-button" onClick={handleMessage}>
          <Button
            active={selectAccount === 5 || selectAccount === 13}
            typeButton={typeButton}
          >
            <img src={poruke} alt="poruke" />
          </Button>
        </div>
        <Button typeButton={typeButton} activation={true}>
          <img src={share} alt="share" onClick={handleShare} />
        </Button>
        <div className="back-home-button" onClick={handleSettings}>
          <Button active={selectAccount === 4} typeButton={typeButton}>
            <img src={settings} alt="settings" />
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
