import React from "react";
import "./Style.css";
import { useRef } from "react";
import axios from "axios";
import { useGlobalContext } from "../../Context/Context";
import Button from "../../Button/Button";

function SendCodeOnlyToMyself({ setCurrentPage, setCurrentPagePassword }) {
  const { controlEmail, baseUrl, letterSize, boldItalic } = useGlobalContext();
  const { typeButton, fontSize, fontStyle } = useGlobalContext();

  console.log(controlEmail);

  const handleSendMYCode = async () => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/verification_codes/send_verification_code.php`,
        {
          user_email: controlEmail,
        }
      );
      console.log(data);
      if (data.status === 200) {
        setCurrentPagePassword(1);
      } else {
        setCurrentPage(0);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  return (
    <div className="send-code-only-to-myself-container">
      <div className="message-top">
        <p
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(
            " KOD će stići na vasu email adresu, molimo vas da isti unesete u polja u prvom pokusaju."
          )}
        </p>
      </div>

      <div className="send-code-btn" onClick={handleSendMYCode}>
        <Button
          activation={true}
          typeButton={typeButton}
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText("POŠALJI MI KOD")}
        </Button>
      </div>
    </div>
  );
}

export default SendCodeOnlyToMyself;
