import { DoubleUser, HalfFilledStar, PostsIcon, StarIdleIcon, UserFilled, UserIdle, UserIdleIcon } from "../assets/icons";
import './userBloc.css'

const UserBloc = (item) => {
    const values = {
        'title': item?.item.title,
        'relation': item?.item.relation,
        'follower_count': item?.item.follower_count,
        'post_count': item?.item.post_count,
        'position': item?.item.position,
        'email': item?.item.email,
        'rating': item?.item.rating,
    }

    // Use this function to fill in the star icon, copy it for the function that colors the post based on relation
    const fillRatingIcon = (rating) => {
        switch (true) {
            case (rating > 4.5):
                return 'full'
            case (rating > 2.5):
                return 'half-full'
            case (rating > 1):
                return 'one-quarter'
            default:
                return 'empty'
        }
    }


  return (
    // <div>{Object.keys(values).map((value,i) => (
    //     <h1 key={i}>{values[value]}</h1>
    // ))}</div>

    <div className={`user-bloc flex-col-center user-${values.relation}`}>
        <div className="user-bloc--user-info flex-apart" style={{alignItems: 'center'}}>
            <div className="user-bloc--user-info--profile-photo-container flex-center-col" style={{borderRadius: '50%', border: '3px solid black', aspectRatio: '1/1', width: '20vw' }} >
                <img src={UserFilled} style={{ width: '11vw'}} />
            </div>
            <div className="user-bloc--user-info--display-id">
                <p className="user-bloc--user-info--display-id--email">{values.email}</p>
                <p className="user-bloc--user-info--display-id--position" style={{fontSize: '1.5rem', fontWeight: '500'}}>{values.position}</p>
            </div>
            <div className="user-bloc--user-info--rating-container flex-center-col" style={{position: 'relative', width: '5rem'}}>
                <img className="user-bloc--user-info--rating-container--image" src={(values.rating > 2.5) ? HalfFilledStar : StarIdleIcon} alt="star" style={{width: '100%'}} />
                <div className="user-bloc--user-info--rating-container--value" style={{position: 'absolute', fontSize: '1.4rem', fontWeight: '700'}}>{values.rating}</div>
            </div>
        </div>
        <div className="user-bloc--user--counts flex-apart">
            <div className="user-bloc--user--counts--follower-count user-bloc--user--counts--count flex" style={{gap: '.5rem', alignItems: 'end'}}>
                <img src={DoubleUser} alt="followers" />
                <p>{values.follower_count}</p>
            </div>
            <div className="user-bloc--user--counts--posts-count user-bloc--user--counts--count flex" style={{gap: '.5rem', alignItems: 'end'}}>
                <img src={PostsIcon} alt="posts" />
                <p>{values.post_count}</p>
            </div>
        </div>
    </div>
  )

}

export default UserBloc