import { useState, useRef, useEffect } from "react";
import Button from "../Button/Button";
import SingleAccount from "../Accounts/SingleAccount";
import Editor from "../Editor/Editor/Editor";
import Message from "../Chat/Message";
import Settings from "../Settings/Settings";
//import data from "./data";
import "./accounts.css";
import axios from "axios";
import { useGlobalContext } from "../Context/Context";
import TrustFolder from "../TrustFolder/TrustFolder";
import HomePage from "../HomePage/HomePage";
import Link from "../Links/Link";
import LanguageApp from "../LanguagePage/LanguageApp/LanguageApp";
import LinksItemModal from "../LinksItemModal/LinksItemModal";
import Profile from "../Profile/Profile";

const Accounts = ({
  selectAccount,
  setSelectAccount,
  setSelectAccountPin,
  setCurrentPageGlobal,
}) => {
  const { baseUrl } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { boldItalic } = useGlobalContext();
  const [accounts, setAccounts] = useState([]);
  const [locations, setLocations] = useState([]);
  const { account, setAccount } = useGlobalContext();
  const [checkBack, setCheckBack] = useState(false);
  const { setLocation } = useGlobalContext();
  const { typeButton } = useGlobalContext();
  const { letterSize } = useGlobalContext();
  const { fontSize } = useGlobalContext();
  const { fontStyle } = useGlobalContext();

  const handleAccount = async (item) => {
    if (item.id === 7) { // Assuming '7' is the specific account ID or any other identifier
      window.location.href = 'https://www.google.com';
      return; // Exit the function after redirecting
    }
    setAccount(item);
    console.log(item);
    try {
      const { data } = await axios.post(
        `${baseUrl}/companies/getUserLocationsByCompanyId.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          company_id: item.id,
        }
      );
      console.log(data);
      if (data.status === 200) {
        console.log("Usao drugi ", data.locations);
        setSelectAccount(2);
        setLocations(data.locations);
      }
    } catch (data) {
      console.log(data);
    }
  };

  const handleChooseLocation = (location) => {
    setSelectAccount(3);
    setLocation(location);
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  console.log(locations);
  useEffect(() => {
    const handleAccountData = async () => {
      try {
        console.log(loggedUser.id);
        console.log(apiKey);
        const { data } = await axios.post(
          `${baseUrl}/companies/getCompaniesByUserId.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
          }
        );
        console.log(data);
        if (data.status === 200) {
          setAccounts(data.companies);
          console.log("{postavljam account");
        }
      } catch (data) {}
      console.log("pozic apiKey");
    };

    console.log("render");
    handleAccountData();
  }, []);

  console.log("Render", selectAccount);
  console.log(account);

  return (
    <>
      {(selectAccount === 2 || selectAccount === 1 || selectAccount === 6) && (
        <div className="accounts-wrapper">
          {selectAccount === 2 && (
            <SingleAccount
              setSelectAccount={setSelectAccount}
              selectAccount={selectAccount}
              locations={locations}
            />
          )}
          {selectAccount === 1 && (
            <div className="wrapper-location">
              <div
                className={
                  "name-account " +
                  `font-size-${parseInt(
                    fontSize === undefined || fontSize === null
                      ? 0
                      : fontSize / 3
                  )}` +
                  ` font-style-${parseInt(
                    fontStyle === undefined || fontStyle === null
                      ? 0
                      : fontStyle / 3
                  )}`
                }
                style={
                  boldItalic.find((element) => parseInt(element) === 2) !==
                  undefined
                    ? {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                        undefined &&
                      boldItalic.find((element) => parseInt(element) === 0) !==
                        undefined
                    ? {
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                    ? {
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined
                    ? {
                        fontWeight: "bold",
                      }
                    : {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                }
              >
                {handleConvertText(account.name.toUpperCase())}
              </div>
              <div className="concret-location">
                {locations.map((location) => {
                  return (
                    <div
                      className="location-name"
                      onClick={() => handleChooseLocation(location)}
                    >
                      <Button typeButton={typeButton} activation={true}>
                        {" "}
                        <div
                          className={
                            "location-name-current " +
                            `font-size-${parseInt(
                              fontSize === undefined || fontSize === null
                                ? 0
                                : fontSize / 3
                            )}` +
                            ` font-style-${parseInt(
                              fontStyle === undefined || fontStyle === null
                                ? 0
                                : fontStyle / 3
                            )}`
                          }
                          style={
                            boldItalic.find(
                              (element) => parseInt(element) === 2
                            ) !== undefined
                              ? {
                                  fontWeight: "normal",
                                  fontStyle: "normal",
                                }
                              : boldItalic.find(
                                  (element) => parseInt(element) === 1
                                ) !== undefined &&
                                boldItalic.find(
                                  (element) => parseInt(element) === 0
                                ) !== undefined
                              ? {
                                  fontWeight: "bold",
                                  fontStyle: "italic",
                                }
                              : boldItalic.find(
                                  (element) => parseInt(element) === 0
                                ) !== undefined
                              ? {
                                  fontStyle: "italic",
                                }
                              : boldItalic.find(
                                  (element) => parseInt(element) === 1
                                ) !== undefined
                              ? {
                                  fontWeight: "bold",
                                }
                              : {
                                  fontWeight: "normal",
                                  fontStyle: "normal",
                                }
                          }
                        >
                          {handleConvertText(location.location_name)}
                        </div>
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div
                className={
                  "logo-account " +
                  `font-size-${parseInt(
                    fontSize === undefined || fontSize === null
                      ? 0
                      : fontSize / 3
                  )}` +
                  ` font-style-${parseInt(
                    fontStyle === undefined || fontStyle === null
                      ? 0
                      : fontStyle / 3
                  )}`
                }
                style={
                  boldItalic.find((element) => parseInt(element) === 2) !==
                  undefined
                    ? {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                        undefined &&
                      boldItalic.find((element) => parseInt(element) === 0) !==
                        undefined
                    ? {
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                    ? {
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined
                    ? {
                        fontWeight: "bold",
                      }
                    : {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                }
              >
                {account.logo === "" ? (
                  handleConvertText(account.name[0].toUpperCase())
                ) : (
                  <img src={account.logo} alt="" />
                )}{" "}
              </div>
            </div>
          )}
          {selectAccount === 6 && (
            <div className="account-list">
              {accounts.map((item, index) => {
                return (
                  <div
                    className="account"
                    onClick={() => handleAccount(item)}
                    key={index}
                  >
                    <Button typeButton={typeButton}>
                      {item.logo === "" ? (
                        <div
                          className={
                            "account-button " +
                            `font-size-${parseInt(
                              fontSize === undefined || fontSize === null
                                ? 0
                                : fontSize / 3
                            )}` +
                            ` font-style-${parseInt(
                              fontStyle === undefined || fontStyle === null
                                ? 0
                                : fontStyle / 3
                            )}`
                          }
                        >
                          <span
                            className={
                              "initial " +
                              `font-size-${parseInt(
                                fontSize === undefined || fontSize === null
                                  ? 0
                                  : fontSize / 3
                              )}` +
                              ` font-style-${parseInt(
                                fontStyle === undefined || fontStyle === null
                                  ? 0
                                  : fontStyle / 3
                              )}`
                            }
                            style={
                              boldItalic.find(
                                (element) => parseInt(element) === 2
                              ) !== undefined
                                ? {
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                  }
                                : boldItalic.find(
                                    (element) => parseInt(element) === 1
                                  ) !== undefined &&
                                  boldItalic.find(
                                    (element) => parseInt(element) === 0
                                  ) !== undefined
                                ? {
                                    fontWeight: "bold",
                                    fontStyle: "italic",
                                  }
                                : boldItalic.find(
                                    (element) => parseInt(element) === 0
                                  ) !== undefined
                                ? {
                                    fontStyle: "italic",
                                  }
                                : boldItalic.find(
                                    (element) => parseInt(element) === 1
                                  ) !== undefined
                                ? {
                                    fontWeight: "bold",
                                  }
                                : {
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                  }
                            }
                          >
                            {handleConvertText(item.name[0].toUpperCase())}
                          </span>
                          <span
                            className={
                              `font-size-${parseInt(
                                fontSize === undefined || fontSize === null
                                  ? 0
                                  : fontSize / 3
                              )}` +
                              ` font-style-${parseInt(
                                fontStyle === undefined || fontStyle === null
                                  ? 0
                                  : fontStyle / 3
                              )}`
                            }
                            style={
                              boldItalic.find(
                                (element) => parseInt(element) === 2
                              ) !== undefined
                                ? {
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                  }
                                : boldItalic.find(
                                    (element) => parseInt(element) === 1
                                  ) !== undefined &&
                                  boldItalic.find(
                                    (element) => parseInt(element) === 0
                                  ) !== undefined
                                ? {
                                    fontWeight: "bold",
                                    fontStyle: "italic",
                                  }
                                : boldItalic.find(
                                    (element) => parseInt(element) === 0
                                  ) !== undefined
                                ? {
                                    fontStyle: "italic",
                                  }
                                : boldItalic.find(
                                    (element) => parseInt(element) === 1
                                  ) !== undefined
                                ? {
                                    fontWeight: "bold",
                                  }
                                : {
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                  }
                            }
                          >
                            {handleConvertText(item.name.toUpperCase())}
                          </span>
                        </div>
                      ) : (
                        <div className="account-button">
                          <img
                            className="account-button-image"
                            src={item.logo}
                            alt=""
                          ></img>
                          <span
                            className={
                              `font-size-${parseInt(
                                fontSize === undefined || fontSize === null
                                  ? 0
                                  : fontSize / 3
                              )}` +
                              ` font-style-${parseInt(
                                fontStyle === undefined || fontStyle === null
                                  ? 0
                                  : fontStyle / 3
                              )}`
                            }
                            style={
                              boldItalic.find(
                                (element) => parseInt(element) === 2
                              ) !== undefined
                                ? {
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                  }
                                : boldItalic.find(
                                    (element) => parseInt(element) === 1
                                  ) !== undefined &&
                                  boldItalic.find(
                                    (element) => parseInt(element) === 0
                                  ) !== undefined
                                ? {
                                    fontWeight: "bold",
                                    fontStyle: "italic",
                                  }
                                : boldItalic.find(
                                    (element) => parseInt(element) === 0
                                  ) !== undefined
                                ? {
                                    fontStyle: "italic",
                                  }
                                : boldItalic.find(
                                    (element) => parseInt(element) === 1
                                  ) !== undefined
                                ? {
                                    fontWeight: "bold",
                                  }
                                : {
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                  }
                            }
                          >
                            {handleConvertText(item.name.toUpperCase())}
                          </span>
                        </div>
                      )}
                    </Button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      {selectAccount === 4 && (
        <Settings
          selectAccount={selectAccount}
          setSelectAccountPin={setSelectAccountPin}
        />
      )}
      {selectAccount === 5 && (
        <Message
          checkBack={checkBack}
          setCheckBack={setCheckBack}
          currentTab={setSelectAccountPin}
          isExternalChat={true}
        />
      )}
      {selectAccount === 11 && (
        <Message
          checkBack={checkBack}
          setCheckBack={setCheckBack}
          currentTab={setSelectAccountPin}
          isExternalChat={false}
        />
      )}
      {selectAccount === 0 && (
        //treba ubaciti home-page

        <HomePage
          setSelectAccount={setSelectAccount}
          setCurrentPage={setCurrentPageGlobal}
        ></HomePage>
      )}
      {selectAccount === 3 && (
        <Editor setSelectAccount={setSelectAccount} account={account} />
      )}
      {selectAccount === 9 && <Link setSelectAccount={setSelectAccount} />}
      {selectAccount === 12 && <Profile />}
      
      {selectAccount === 8 && (
        <LanguageApp
          setCurrentPageGlobal={setCurrentPageGlobal}
          setSelectAccount={setSelectAccount}
        />
      )}
    </>
  );
};

export default Accounts;
