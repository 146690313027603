import React, { useState, useRef } from 'react';
import { 
    SaveIdleIcon, UserIdle, EyeIcon, PaperplaneIcon, CommentIcon, StarIcon, 
    EditIcon, BackgroundIcon, StarIdleIcon, StarActiveIcon, CopyIdleIcon, PlaneIdleIcon, CommentIdleIcon, 
    PlaneActiveIcon,
    ThickBorderedStar,
    EyeIdleIcon,
    OpenEyeIcon
} from '../assets/icons'
import './commentBloc.css';

import CircledButton from "../buttons/circled-button/CircledButton";

const StatIndex = ({type, image, value}) => (
    <span className={`comment-bloc--poster-bloc--stats--${type} comment-bloc--poster-bloc--stats--stat flex`}>
        <img src={image} alt={type} />
        <p>{value}</p>
    </span>
);

const CommentBloc = ({title, text, time_of_creation, date_of_creation, time_of_update, image, views, reposts, comments, rating, category_id, created_by}, key) => {
    
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isHidden, setIsHidden] = useState(false);
    const [showComments, setShowComments] = useState(false); // State for showing comments

    const contentRef = useRef(null)
    const findCategoryColor = (id) => {
        switch (id) {
            case 1:
                return 'red';
            case 2:
                return 'green';
            case 3:
                return '#161D6F';
            case 4:
                return 'brown';
            default:
                return 'black';
        }
    };

    let root = document.documentElement;
    root.style.setProperty('--category-color', findCategoryColor(category_id));


    const handleCommentClick = () => {
        setShowComments(!showComments); // Toggle the visibility of comments
    };

    const handleHideClick = () => {
        setIsHidden(!isHidden)
        const element = document.getElementsByClassName('comment-bloc')[0]
        element.style.opacity = isHidden ? 0.5 : 1
    }

    if (!title) return

    return (
        <>
            <div 
                className="comment-bloc flex-col" 
                onClick={() => setIsCollapsed(!isCollapsed)} 
                style={{borderColor: findCategoryColor(category_id)}}
            >
                <div className="comment-bloc--poster-bloc flex-col" style={{}}>
                    <div className="comment-bloc--poster-bloc--user flex">
                        <img 
                            src={image.substring(0,10) === "data:image" ? image : UserIdle} 
                            alt="User" 
                            style={{borderColor: findCategoryColor(category_id)}} 
                        />
                        <div className="comment-bloc--poster-bloc--user--info">
                            
                            <p>
                                {created_by?.profile_profession} 
                                <span style={{fontSize: '1rem'}}>
                                    {created_by?.profile_name && `(${created_by?.profile_email})`}
                                </span>
                            </p>
                            <p>{time_of_creation} {date_of_creation}</p>
                        </div>
                    </div>
                </div>
                <div className="comment-bloc--content-bloc">
                    <p className={`description flex`}>{text}</p>
                    <div className="content" ref={contentRef} 
                    style={{height: !isCollapsed ? `${contentRef?.current?.scrollHeight}px` : '0px'}}>
                        
                    </div>
                </div>
            </div>
            {!isCollapsed && (
                <>
                    <div className="feed-actions" style={{marginTop: '1rem'}}>
                        <div onClick={handleCommentClick}>
                            <img src={CommentIdleIcon} alt="Comment" />
                        </div>
                        <div onClick={handleHideClick}>
                            <img src={isHidden ? EyeIdleIcon : OpenEyeIcon} alt="Comment" style={{height: !isHidden && '1.5rem'}} />
                        </div>
                    </div>
                    {showComments && (
                        <div className="comments">
                            <textarea rows="5"></textarea>
                            <CircledButton idleImage={PlaneIdleIcon} activeImage={PlaneActiveIcon} handleButtonEffect={() => console.log("Add Comment")}/>
                          
                        </div>
                        
                    )}
                </>
            )}
        </>
    );
}

export default CommentBloc;
