import { useEffect, useState } from 'react'
import './index.css'
import './index'
import { Route, Routes } from 'react-router-dom'
import { FeedPage, LandingPage, UserFeed, UserPage } from './pages'
import { FeedPost, PostFormContent } from './posts'
import { ApiData, Connections } from './data/api'
import App from '../App'

import { CircledButton } from './buttons';
import { BackActiveIcon, BackIdleIcon, EyeIdleIcon, FavIdleIcon, SortIdleIcon } from './assets/icons';
import { CommentBloc, UserBloc } from './index'
import {fetchData} from './data/fetchAPI'

const Routing = () => {
  const [isSticky, setIsSticky] = useState(false); // State for sticky behavior
  const [flags, setFlags] = useState()
  

    useEffect(() => {
        const handleScroll = () => {
            const controlButtons = document.querySelector('.control-buttons');
            if (controlButtons) {
                let offsetTop = controlButtons.offsetTop;
                let currentY = window.scrollY
                console.log(offsetTop,currentY)
                if (currentY > 229) setIsSticky(true)
                else setIsSticky(false)
            }
        };
        fetchData().then(data => setFlags(data))
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  const postList = ApiData.posts

  const FeedList = ({list}) => {
    return (
      <div className='flex-center-col feed-list' style={{padding: '0 1rem'}}>
        <>
          <div className={`control-buttons ${isSticky ? 'sticky' : ''}`}>
            <CircledButton idleImage={BackIdleIcon} activeImage={BackActiveIcon} handleButtonEffect={() => console.log("back")}/>
            <CircledButton idleImage={EyeIdleIcon} extraStyling={{width: '11vw'}} />
            <CircledButton idleImage={SortIdleIcon} extraStyling={{width: '9vw'}} />
            <CircledButton idleImage={FavIdleIcon} extraStyling={{height: '11vw', width: '9vw', marginBottom: '1vw'}} />

          </div>
        </>
        <div className={`posts-list flex-center-col posts-list-${isSticky ? 'sticky' : ''}`} style={{gap: '1rem', width: '100%'}}>
          {   
          list.map((item, key) => (
          <FeedPost key={key} {...item} />
          ))}
        </div>
      </div>
    );
  }
  
  return (
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/social'>
        <Route index element={<LandingPage />}/>
        <Route path='feed' element={<FeedPage />}>
          <Route index element={<FeedList list={postList} />} />
          <Route path='newpost' element={<PostFormContent />} />
        </Route>
        <Route path='personal'>
          <Route index element={<UserFeed />} />
          <Route element={<div style={{marginBottom: '1rem'}}>
            <UserBloc item={Connections.users[0]} />
            <UserBloc item={Connections.users[1]} />
            <UserBloc item={Connections.users[2]} />
            <CommentBloc {...postList[1]} />
            </div>} path='user-bloc' />
          <Route path='user-page' element={<UserPage />} />
        </Route>
        <Route path='flags-api' element={
          <ul>{flags?.map((flag,key) => (<li key={key}>{flag.lang_name}</li>))}</ul>} 
        />
      </Route>
    </Routes>
  )
}

export default Routing