import { useEffect, useState } from "react";
import Button from "../Button/Button";
import send from "../picture/Send.png";
import "./findMessage.css";
import { useGlobalContext } from "../Context/Context";
import axios from "axios";
import { format } from "date-fns";
const FindMessage = ({
  searchTearm,
  data,
  setData,
  setAddChat,
  setSelectAddChat,
  handleSelectMessage,
}) => {
  console.log("Poziva se find", searchTearm);
  const { typeButton } = useGlobalContext();
  const [otherUser, setOtherUser] = useState({
    id: searchTearm.id,
    name: "",
    user_picture: "",
    user_mail: searchTearm.name,
  });
  const { apiKey, baseUrl, loggedUser } = useGlobalContext();

  const handleSendMessage = async () => {
    const wrapperMessage = document.querySelector(".wrapper-input");
    const message = wrapperMessage.querySelector("input");
    console.log(message.value);
    console.log(loggedUser.user_email);

    try {
      const scrollPosition = document.querySelector(".message-list");

      const { data } = await axios.post(
        `${baseUrl}/chat/createChatRoomAfterEmail.php`,
        {
          logged_user_id: loggedUser.id,
          other_user_id: searchTearm.id,
          api_key: apiKey,
        }
      );

      console.log(data);
      if (data.status === 200 || data.status === "exists") {
        if (message.value === "") return;
        if (data.chat_room_id === 0) return;
        const sendData = await axios.post(`${baseUrl}/chat/sendMessage.php`, {
          sender: loggedUser.user_email,
          text: message.value,
          date: format(new Date(), "dd.MM.yyyy HH:mm"),
          api_key: apiKey,
          chat_room_id: parseInt(data.chat_room_id),
        });
        const selectMessage = handleSelectMessage({ id: data.profile2_id });
        if (selectMessage) {
          setAddChat(false);
          setSelectAddChat(false);
        }
        setSelectAddChat(true);
      }
    } catch (error) {
      console.log(error);
    }
    console.log(data.profile2_id);
  };

  useEffect(async () => {
    try {
      const sendData = await axios.post(
        `${baseUrl}/profiles/getOtherProfileInChat.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          other_user_id: searchTearm.id,
        }
      );
      console.log(sendData.data.profile);
      if (sendData.status === 200) {
        setOtherUser({
          ...otherUser,
          name: sendData.data.profile.cp_name,
          user_picture: sendData.data.profile.cp_image,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  console.log(otherUser.user_picture);
  return (
    <div className="wrapper-profile">
      <div className="wrapper-info-profile">
        <div className="profile-picture">
          {otherUser.user_picture !== "" ? (
            <img src={otherUser.user_picture} alt=""></img>
          ) : (
            <span>
              {otherUser.name !== ""
                ? otherUser.name.charAt(0).toUpperCase()
                : otherUser.user_mail.charAt(0).toUpperCase()}
            </span>
          )}
        </div>
        {otherUser.name !== "" && <span>{otherUser.name}</span>}
        <span>{otherUser.user_mail}</span>
      </div>
      <div className="wrapper-input">
        <input type="text" />
        <div className="wrapper-input-button" onClick={handleSendMessage}>
          <Button image={send} typeButton={typeButton} />
        </div>
      </div>
    </div>
  );
};

export default FindMessage;
