import React, { useRef } from "react";
import "./Style.css";
import Button from "../../Button/Button";
import { useGlobalContext } from "../../Context/Context";

function SendMyselfOrTrustedUser({ setCurrentPagePassword }) {
  const textRef = useRef(null);
  const { selectedLanguage } = useGlobalContext();
  const { typeButton, letterSize, boldItalic, fontSize, fontStyle } =
    useGlobalContext();
  // Kopiranje teksta
  const handleCopyClick = () => {
    if (textRef.current) {
      const textToCopy = textRef.current.innerText;
      navigator.clipboard.writeText(textToCopy).then(
        () => {
          alert("Tekst je uspesno kopiran.");
          setCurrentPagePassword(1);
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      );
    } else {
      console.error("Text reference is null");
    }
  };

  // Slanje mail-a iz svog servisa
  const handleClick = (event) => {
    event.preventDefault();
    console.log("Otvaram slanje maila.");
    setCurrentPagePassword(1);
    window.location.href = `mailto:verify@nadzor.app?subject=Verification&body=${selectedLanguage.data.dear},${selectedLanguage.data.verificationCode}`;
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  return (
    <div className="send-or-copy-mail-container">
      <div className="message-top">
        <p
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(selectedLanguage.data.dear)},
          <br />
          <br />
          {handleConvertText(
            "ukoliko sa ovog uredjaja ne mozete poslati zahtev sa mail adrese kojom ste registrovani, molimo vas da tekst koji ce se nakon potvrde za slanje mail-a generisati u vasem mail servisu u celosti kopirate i posaljete sa adrese sa kojom ste registrovani."
          )}
        </p>
      </div>

      <div className="send-to-me-btn" onClick={handleClick}>
        <Button
          typeButton={typeButton}
          activation={true}
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText("POŠALJI MAIL")}
        </Button>
      </div>

      <div className="message-bottom" ref={textRef}>
        <p
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(selectedLanguage.data.dear)},
          <br />
          <br />
          {handleConvertText(selectedLanguage.data.verificationCode)}
        </p>
      </div>

      <div className="send-to-me-btn" onClick={handleCopyClick}>
        <Button
          activation={true}
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText("KOPIRAJ TEKST")}
        </Button>
      </div>
    </div>
  );
}

export default SendMyselfOrTrustedUser;
