import { Outlet } from 'react-router-dom'

// import React, { useState, useEffect} from 'react';
// import axios from "axios";

import PostForm from "../../posts/post-form/PostForm";
import './feed.css';
import {useFetchPosts} from '../../data/useFetchPosts';


const Feed = () => {
    const posts = useFetchPosts();

    console.log(posts);
    return (
        <div className='feed-page test flex-col'>
       
            <div className="feed-page--form flex-center-col">   
                <PostForm />
            </div>
            <Outlet />
        </div>
    );
}

export default Feed;
