import React, { useEffect, useRef, useState } from "react";
import "./Style.css";
import plane from "./icons/plane.png";
import planeLight from "./icons/plane-light.png"; // Import the light version of the plane image
import Button from "../../Button/Button";
import { useGlobalContext } from "../../Context/Context";
import axios from "axios";

function EnterCode({ setCurrentPage }) {
  const { controlEmail, baseUrl, setLoggedUser, setApiKey } =
    useGlobalContext();
  const { typeButton, letterSize, boldItalic, fontSize, fontStyle } =
    useGlobalContext();
  const [inputValues, setInputValues] = useState(["", "", ""]);
  const [isCorrect, setIsCorrect] = useState(false);
  const inputsRef = useRef([]);

  // Handle input value change
  const handleInputChange = (index, value) => {
    if (value === " ") return; // Ignore space character

    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (index < inputsRef.current.length - 1 && value !== "") {
      inputsRef.current[index + 1].focus();
    }
  };

  // Handle backspace key press
  const handleBackspace = (index, event) => {
    if (
      event.keyCode === 8 &&
      index > 0 &&
      inputsRef.current[index].value === ""
    ) {
      inputsRef.current[index - 1].focus();
    }
  };

  // Handle button click
  const handleButtonClick = async () => {
    const enteredCode = inputValues.join("");
    let inputValuesString = "";
    inputValues.forEach((value) => {
      inputValuesString += value;
    });
    try {
      const { data } = await axios.post(
        `${baseUrl}/recovery_codes/validateCode.php`,
        {
          user_email: controlEmail,
          code: inputValuesString,
        }
      );
      console.log(data);
      if (data.status === 200) {
        const mailUser = {
          id: 0,
          user_email: controlEmail,
          user_pin: "",
          isTemp: data.pattern_use,
          pattern: data.user_pattern,
          privilage: "",
          firstContact: "",
          secondContact: "",
        };

        setApiKey(data.api_key);
        setLoggedUser(mailUser);
        setCurrentPage(4);
        setIsCorrect(true);
      } else {
        setCurrentPage(0);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  return (
    <div className="enter-code-container">
      <h1
        className={
          `font-size-${parseInt(
            fontSize === undefined || fontSize === null ? 0 : fontSize / 3
          )}` +
          ` font-style-${parseInt(
            fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
          )}`
        }
        style={
          boldItalic.find((element) => parseInt(element) === 2) !== undefined
            ? {
                fontWeight: "normal",
                fontStyle: "normal",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined &&
              boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
            ? {
                fontWeight: "bold",
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 0) !==
              undefined
            ? {
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
              undefined
            ? {
                fontWeight: "bold",
              }
            : {
                fontWeight: "normal",
                fontStyle: "normal",
              }
        }
      >
        {handleConvertText("UNESI KOD")}
      </h1>
      <div className="input-group">
        {[...Array(3)].map((_, index) => (
          <input
            className="enter-code-input"
            type="text"
            maxLength="1"
            key={index}
            ref={(input) => (inputsRef.current[index] = input)}
            value={inputValues[index]}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleBackspace(index, e)}
          />
        ))}
      </div>
      <div className="confirm-code-button" onClick={handleButtonClick}>
        <Button activation={true} typeButton={typeButton}>
          <img src={isCorrect ? planeLight : plane} alt="" />
        </Button>
      </div>

      <div className="message-verif-code">
        <p
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(
            "Unesite KOD koji je upravo stigao na email adresu sa kojom ste registrovali ovaj nalog."
          )}
        </p>
      </div>
    </div>
  );
}

export default EnterCode;
