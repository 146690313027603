import { React, useState, useEffect } from "react";
import "./button.css";
import { useGlobalContext } from "../Context/Context";

function Button({
  image,
  active,
  children,
  activation,
  isDisabled,
  isCorrect,
  typeButton,
  colorActive,
}) {
  let [isActive, setActive] = useState(false);
  let [activeClass, setActiveClass] = useState("");
  let [idleClass, setIdleClass] = useState("");
  const { colorButton, setColorButton } = useGlobalContext();
  const [currentColor, setCurrentColor] = useState("");

  useEffect(() => {
    if (typeButton === 0 || typeButton === undefined) {
      setIdleClass("dos-btn");
      setActiveClass("dos-btn-active");
    }
    if (typeButton === 1) {
      setIdleClass("dos-btn-round-none-border");
      setActiveClass("dos-btn-active-round-none-border");
    }
    if (typeButton === 2) {
      setIdleClass("dos-btn-round-border");
      setActiveClass("dos-btn-active-round-border");
    }
  }, [typeButton]);

  useEffect(() => {
    console.log("Pozvao se boja button-a gggggg");
    if (colorActive) {
      setCurrentColor("");
    } else {
      setCurrentColor(colorButton);
    }
    /*if (typeButton === 0 || typeButton === undefined) {
      setIdleClass("dos-btn");
      setActiveClass("dos-btn-active");
    }
    if (typeButton === 1) {
      setIdleClass("dos-btn-round-none-border");
      setActiveClass("dos-btn-active-round-none-border");
    }
    if (typeButton === 2) {
      setIdleClass("dos-btn-round-border");
      setActiveClass("dos-btn-active-round-border");
    }
*/
  }, [colorButton, setColorButton]);

  useEffect(() => {
    setActive(active);
  }, [active]);

  const handleButtonActive = (event) => {
    if (activation) return;
    setActive((current) => !current);
  };

  const checkActiveNotCorrect = () => {
    if (isCorrect) return `${idleClass} correct`;
    else return idleClass;
  };

  return (
    <button
      className={isActive ? activeClass : checkActiveNotCorrect()}
      onClick={handleButtonActive}
      disabled={isDisabled}
      style={{
        backgroundColor: currentColor === "" ? "transparent" : currentColor,
      }}
    >
      {image !== undefined && <img src={image} alt="" />}
      {children}
    </button>
  );
}

export default Button;
