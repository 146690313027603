import { useState } from "react";
import "./homePage.css";
import Company from "../picture/Company.png";
import Tempo from "../picture/Tempo.png";
import WwwLink from "../picture/WwwLink.png";
import PdfItem from "../picture/PdfItem.png";
import PeopleHome from "../picture/PeopleHome.png";
import LanguageHome from "../picture/LanguageHome.png";
import WwwData from "../picture/WwwData.png";
import AddPeople from "../picture/AddPeople.png";
import AddMessage from "../picture/AddMessage.png";
import LogOut from "../picture/LogOut.png";
import SearchHome from "../picture/SearchHome.png";

import Button from "../Button/Button";
import { useGlobalContext } from "../Context/Context";

const HomePage = ({
  setSelectAccount = { setSelectAccount },
  setCurrentPage,
}) => {
  const { setLoggedUser, setSelectedLanguage } = useGlobalContext();
  const { apiKey, baseUrl, loggedUser } = useGlobalContext();
  const {
    typeButton,
    setColorButton,
    setTypeButton,
    setLetterSize,
    setBoldItalic,
    setFontSize,
    setFontStyle,
    setIsFromHome,
  } = useGlobalContext();

  const array = [
    { image: Tempo },
    { image: WwwLink },
    { image: LanguageHome },
    { image: WwwData },
    { image: AddPeople },
    { image: AddMessage },
    { image: LogOut },
    { image: SearchHome },
  ];

  const handleChooseItem = (index) => {
    console.log(index);
    if (index === 6) {
      // ODJAVA
      localStorage.removeItem("editor-user");
      localStorage.removeItem("api-key");
      localStorage.removeItem("select-language");
      setSelectedLanguage(undefined);
      setCurrentPage(0);
      setLoggedUser(undefined);
      setIsFromHome(false);
      setColorButton("#CCC");
      setTypeButton(0);
      setLetterSize(undefined);
      setBoldItalic([]);
      setFontSize();
      setFontStyle();
    }
    if (index === 0) {
      //back to start
      setCurrentPage(0);
      setLoggedUser(undefined);
      setIsFromHome(true);
    }
    if (index === 1) {
      const link = document.createElement("a");
      link.href = "https://web.nadzor.app/create.php";
      link.click();
    } else {
      setSelectAccount(index + 6);
    }
  };

  return (
    <>
      <h2 className="home-email">{loggedUser.user_email}</h2>
      <div className="home-page">
        {array.map((item, index) => {
          return (
            <div
              className="wrapper-item-button"
              onClick={() => handleChooseItem(index)}
            >
              <Button i typeButton={typeButton}>
                <div className="home-page-image">
                  <img src={item.image} alt=""></img>
                </div>
              </Button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default HomePage;
