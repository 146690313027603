import { useEffect, useState } from "react";
import axios from "axios";

import { useGlobalContext } from "../../Context/Context";

export const useFetchPosts = () => {
  const [posts, setPosts] = useState({});

  const { apiKey, loggedUser } = useGlobalContext();

  useEffect(() => {
    const body = {
      api_key: apiKey,
      user_id: loggedUser.id,
      profile_id: loggedUser.id,
    };

    axios
      .post("https://data.nadzor.app/api/relations/posts/readAllPostsTest.php", body)
      .then((data) => {
        setPosts(data.data.data);
      });
  }, []);

  return posts;
};
